import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'SearchByTag',
    pure: false
})
export class SearchByTagPipe implements PipeTransform {

    transform(items: Array<any>, searchText: string, searchTags: Array<string>): Array<any> {
        
        // Name
        items = items.filter((item: any) => {
            return (item.name.toLowerCase().includes(searchText.toLowerCase()) || item.description.toLowerCase().includes(searchText.toLowerCase()))
        });

        if (searchTags && !searchTags.includes('All')) {
            searchTags = searchTags.map((t: string) => t.toLowerCase());

            items = items.filter((item: any) => {
                if (item.tags && item.tags.length > 0) {
                    let itemTags = item.tags.map((t: string) => t.toLowerCase().trim());
                    itemTags = itemTags.filter((iTag: string) => searchTags.includes(iTag));
                    if (itemTags.length > 0) return item;
                }
            });
        }

        return items;
    }
}